import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import userService from "../../services/user.service";
import { setMessage } from "../messageSlice";

export const getUser = createAsyncThunk(
  "userSlice/getUser",
  async (thunkApi) => {
    try {
      return userService.fetchUser().then((response) => response.data || {});
    } catch (e) {
      // @ts-ignore
      return e?.response;
    }
  }
);

export const fetchUserCredentials = createAsyncThunk(
  "userSlice/fetchUserCredentials",
  async (payload: any, thunkApi) => {
    try {
      return userService
        .getCredentials()
        .then((response) => response.data || {});
    } catch (e) {
      // @ts-ignore
      return e?.response;
    }
  }
);

export const manageBooks = createAsyncThunk(
  "userSlice/manageBooks",
  async (thunkAPI) => {
    return userService.fetchUserBooks().then((response) => response.data || []);
  }
);

export const userPlan = createAsyncThunk("userSlice/userPlan", async () => {
  try {
    return userService.fetchUserPlans().then((response) => response.data || {});
  } catch (e) {
    // @ts-ignore
    return e?.response;
  }
});

export const getUserAgency = createAsyncThunk(
  "userSlice/getUserAgency",
  async () => {
    try {
      return userService.fetchAgency().then((response) => response.data || {});
    } catch (e) {
      // @ts-ignore
      return e?.response;
    }
  }
);

export const getUserAutomation = createAsyncThunk(
  "userSlice/getUserAutomation",
  async () => {
    try {
      return userService
        .fetchAutomations()
        .then((response) => response.data || {});
    } catch (e) {
      // @ts-ignore
      return e?.response;
    }
  }
);

export const getUserDFY = createAsyncThunk("userSlice/getUserDFY", async () => {
  try {
    return userService.fetchDFY().then((response) => response.data || {});
  } catch (e) {
    // @ts-ignore
    return e?.response;
  }
});

export const getPixaBay = createAsyncThunk(
  "userSlice/getPixaBay",
  async (url: string) => {
    try {
      return userService
        .fetchPixaBay(url)
        .then((response) => response.data || {});
    } catch (e) {
      // @ts-ignore
      return e?.response;
    }
  }
);

export const getEcoverTemplates = createAsyncThunk(
  "userSlice/getEcoverTemplates",
  async () => {
    try {
      return userService
        .fetchECoverTemplates()
        .then((response) => response.data || {});
    } catch (e) {
      // @ts-ignore
      return e?.response;
    }
  }
);

const initialState = {
  user: {},
  userLoading: false,
  books: "",
  covers: "",
  credentials: "",
  myPlan: "",
  agency: "",
  automations: "",
  dfy: "",
  automationSearch: "",
  ecover: "",
};

// @ts-ignore
const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    updatePixaBay: (state, action) => {
      //@ts-ignore
      state.automationSearch = [];
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getUser.fulfilled, (state, action) => {
      state.user = action.payload?.data;
      // state.userLoading = false;
    });

    builder.addCase(manageBooks.fulfilled, (state, action) => {
      state.books = action.payload?.data;
      state.covers = action.payload?.cover;
    });
    builder.addCase(fetchUserCredentials.fulfilled, (state, action) => {
      if (action.payload.status) state.credentials = action.payload?.key;
      else state.credentials = "";
    });
    builder.addCase(userPlan.fulfilled, (state, action) => {
      if (action.payload.status) state.myPlan = action.payload?.data;
      else state.myPlan = "";
    });
    builder.addCase(getUserAgency.fulfilled, (state, action) => {
      if (action.payload.status) state.agency = action.payload?.data;
      else state.agency = "";
    });

    builder.addCase(getUserAutomation.fulfilled, (state, action) => {
      if (action.payload.status) state.automations = action.payload?.data;
      else state.automations = "";
    });

    builder.addCase(getUserDFY.fulfilled, (state, action) => {
      if (action.payload.status) state.dfy = action.payload?.data;
      else state.dfy = "";
    });

    builder.addCase(getEcoverTemplates.fulfilled, (state, action) => {
        console.log(action.payload)
      if (action.payload.status) state.ecover = action.payload?.data;
      else state.ecover = "";
    });

    builder.addCase(getPixaBay.fulfilled, (state, action) => {
      // console.log(action.payload)
      //@ts-ignore
      state.automationSearch = action.payload?.hits || [];
    });
  },
});

const { reducer } = userSlice;
export const { updatePixaBay } = userSlice.actions;
export default reducer;
