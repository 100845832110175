import axios from "axios";
import {loginHeader} from "./authServices";
import {endPoint} from "./user.service";
import authHeader from "./authHeader";
import {CreateFormData} from "../hooks/CreateFormData";


const adminHeader = {
    Authorization: `Token ${localStorage.adminToken}`,
}
export const adminLogin = async (payload: loginHeader) => {
    try {
        return axios.post(`${endPoint}/api/admin/login`,
            payload,
        )
    } catch (e: any) {

        return e?.response

    }
}

export const fetchEbooks = async () => {
    try {
        return axios.get(`${endPoint}/api/admin/ebooks`,
            {
                headers: adminHeader,
            }
        )
    } catch (e: any) {

        return e?.response

    }
}

export const fetchFlipBooks = async () => {
    try {
        return axios.get(`${endPoint}/api/admin/flip-books`,
            {
                headers: adminHeader,
            }
        )
    } catch (e: any) {

        return e?.response

    }
}

export const fetchUsers = async () => {
    try {
        return axios.get(`${endPoint}/api/admin/users`,
            {
                headers: adminHeader,
            }
        )
    } catch (e: any) {

        return e?.response

    }
}

export const fetchTemplates = async () => {
    try {
        return axios.get(`${endPoint}/api/admin/templates`,
            {
                headers: adminHeader,
            }
        )
    } catch (e: any) {

        return e?.response

    }
}

export const fetchCovers = async () => {
    try {
        return axios.get(`${endPoint}/api/admin/covers`,
            {
                headers: adminHeader,
            }
        )
    } catch (e: any) {

        return e?.response

    }
}

export const fetchAdminDetails = async () => {
    // console.log(localStorage.adminToken)
    return axios.post(`${endPoint}/api/user-details`,
        {},
        {
            headers: adminHeader,
        }
    )
}

export const fetchPlans = async () => {
    return axios.get(`${endPoint}/api/admin/plans`,
        {
            headers: adminHeader,
        }
    )
}

export const delUser = async (payload: { userid: number | string }) => {
    return axios.post(`${endPoint}/api/admin/delete-user`,
        payload,
        {
            headers: adminHeader,
        }
    )
}

export const createUser = async (payload: { username: string; email: string; firstname: string; lastname: string }) => {
    return axios.post(`${endPoint}/api/admin/add-user`,
        payload,
        {
            headers: adminHeader,
        }
    )
}


export const updateUser = async (payload: { username: string; email: string; firstname: string; lastname: string; userid: string | number;password:string; is_active:number }) => {
    return axios.post(`${endPoint}/api/admin/edit-user`,
        payload,
        {
            headers: adminHeader,
        }
    )
}

export const createPlan = async (payload: { name: string }) => {
    return axios.post(`${endPoint}/api/admin/add-plan`,
        payload,
        {
            headers: adminHeader,
        }
    )
}

export const removePlan = async (payload: { id: string | number }) => {
    return axios.post(`${endPoint}/api/admin/delete-plan`,
        payload,
        {
            headers: adminHeader,
        }
    )
}

export const removeTemplate = async (payload: { id: string | number; images: Array<string> | any, cover: string }) => {
    return axios.post(`${endPoint}/api/admin/delete-template`,
        payload,
        {
            headers: adminHeader,
        }
    )
}

export const createTemplate = async (payload: { content: string | null, image: File, id:number|string }) => {

    return axios.post(`${endPoint}/api/admin/create-template`,
        CreateFormData(payload),
        {
            headers: adminHeader,
        }
    )
}

export const templateDetails = async (payload: { id: string | number }) => {
    return axios.post(`${endPoint}/api/admin/view-template`,
        payload,
        {
            headers: adminHeader,
        }
    )
}

export const updateTemplateContent = async (payload: { id: string | number; content: any }) => {
    return axios.post(`${endPoint}/api/admin/update-template`,
        payload,
        {
            headers: adminHeader,
        }
    )
}

export const createTemplateImage = async (payload: { template: string | number, image: File }) => {
    return axios.post(`${endPoint}/api/admin/upload-template-image`,
        CreateFormData(payload),
        {
            headers: adminHeader,
        }
    )
}

export const userPlans = async () => {
    return axios.post(`${endPoint}/api/admin/user-plans`,
        {},
        {
            headers: adminHeader,
        }
    )
}

export const changeUserPlan = async (payload:{userid:number|string;plan:string;value:number}) => {
    return axios.post(`${endPoint}/api/admin/change-plan`,
        payload,
        {
            headers: adminHeader,
        }
    )
}

export const adminDashboard = async()=>{
    return axios.get(`${endPoint}/api/admin/breakdown`,
        {
            headers: adminHeader,
        }
    )
}

