import React, { useEffect } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
// import './flipbook.css'
import "./App.css";
import "./style.css"
import {RouteHandler} from "./routeHandler";

const App = () => {
  const navigate = useNavigate();
  const location = useLocation();
  useEffect(() => {
    location.pathname === "/" && navigate("/dashboard/");
  }, [location.pathname, navigate]);

  useEffect(()=>{
    window.scroll(0,0)
  }, [location])
  

  return (
    <>
      {/*<Outlet />*/}
      <RouteHandler/>
    </>
  );
};

export default App;
