export const PREFIX = "scena-";
export const PAGE_PREFIX = "page-";
export const PAGESIZE_PIXELS = {
  width: 800,
  height: 1100,
};
export const NODE_DEFAULT_POSITION = {
  x: "45%",
  y: "20%",
};
