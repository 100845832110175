import axios from "axios";
import {
  PDFDocument,
  // StandardFonts,
  // rgb,
} from "pdf-lib";
import { PDFMetaData } from "../interfaces/interfaces";
import authHeader from "./authHeader";

const createNewBlankPDFFile = async (pdfMetaData: PDFMetaData) => {
  // Create a new PDFDocument
  const pdfDoc = await PDFDocument.create();

  // Set PDFDocuments MetaData
  pdfDoc.setTitle(pdfMetaData.title);
  pdfDoc.setAuthor(pdfMetaData.author);
  pdfDoc.setSubject(pdfMetaData.subject);
  pdfDoc.setKeywords(pdfMetaData.keywords);
  pdfDoc.setProducer("AppClick Technology");
  pdfDoc.setCreator("appclick tech (https://appclicktech.net)");
  pdfDoc.setCreationDate(pdfMetaData.creationDate);
  pdfDoc.setModificationDate(pdfMetaData.modificationDate);

  // Add a blank page to the document
  pdfDoc.addPage();

  // Serialize the PDFDocument to bytes (a Uint8Array)
  const pdfDataUri = await pdfDoc.saveAsBase64({ dataUri: true });
  return pdfDataUri;
};

const createPdfData = (data: {
  // file: File;
  // pdfDataUri: string;
  title: string;
  author: string;
  subject: string;
  keywords: string;
  // creationDate: Date;
  // modificationDate: Date;
  owner?: number;
  schema: string
}) => {
  return axios.post(`${process.env.REACT_APP_API_URL}/pdfs/`, data, {
    headers: authHeader(),
  });
};

const updatePdfData = (data: {
  id: string;
  schema: string;
  html: string | undefined;
}) => {
  return axios.patch(
    `${process.env.REACT_APP_API_URL}/pdfs/${data.id}/`,
    data,
    {
      headers: authHeader(),
    }
  );
};

const getPdfData = (id: string) => {
  return axios.get(`${process.env.REACT_APP_API_URL}/pdfs/${id}/`, {
    headers: authHeader(),
  });
};

const getUserPdfs = (userId: number) => {
  return axios.get(`${process.env.REACT_APP_API_URL}/pdfs/?owner=${userId}`, {
    headers: authHeader(),
  });
};

const generatePdf = (data: string) => {
  return axios.post(`${process.env.REACT_APP_API_URL}/generate-pdf/`, data, {
    headers: authHeader(),
  });
};

const pdfService = {
  createNewBlankPDFFile,
  createPdfData,
  updatePdfData,
  getPdfData,
  getUserPdfs,
  generatePdf,
};

export default pdfService;
