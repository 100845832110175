import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {
    adminDashboard,
    fetchAdminDetails,
    fetchCovers,
    fetchEbooks,
    fetchFlipBooks, fetchPlans,
    fetchTemplates,
    fetchUsers, templateDetails, userPlans
} from "../../services/adminServices";
import userService from "../../services/user.service";
import {bookDetails} from "../ebook/eBookSlice";

export const adminDetails = createAsyncThunk(
    'adminSlice/adminDetails',
    async () => {
        return await fetchAdminDetails()
            .then(response => response?.data)
    }
)

export const getEbooks = createAsyncThunk(
    'adminSlice/getEbooks',
    async () => {
        return await fetchEbooks()
            .then(response => response?.data)
    }
)
export const getFlipBooks = createAsyncThunk(
    'adminSlice/getFlipBooks',
    async () => {
        return await fetchFlipBooks()
            .then(response => response?.data)
    }
)
export const getTemplates = createAsyncThunk(
    'adminSlice/getTemplates',
    async () => {
        return await fetchTemplates()
            .then(response => response?.data)
    }
)

export const getUsers = createAsyncThunk(
    'adminSlice/getUsers',
    async () => {
        return await fetchUsers()
            .then(response => response?.data)
    }
)

export const getCovers = createAsyncThunk(
    'adminSlice/getCovers',
    async () => {
        return await fetchCovers()
            .then(response => response?.data)
    }
)

export const getPlans = createAsyncThunk(
    'adminSlice/getPlans',
    async () => {
        return await fetchPlans()
            .then(response => response?.data)
    }
)

export const getAdminDashboard = createAsyncThunk(
    'adminSlice/getAdminDashboard',
    async () => {
        return await adminDashboard()
            .then(response => response?.data)
    }
)

export const getTemplateDetails = createAsyncThunk(
    'adminSlice/bookDetails',
    async (payload: { id: string | number }, thunkApi) => {
        try {

            return templateDetails(payload)
                .then(response => response?.data || {})
        } catch (e) {
            // @ts-ignore
            return e?.response

        }


    }
)

export const getUserPlans = createAsyncThunk(
    'adminSlice/getUserPlans',
    async () => {
        try {

            return userPlans()
                .then(response => response?.data || {})
        } catch (e) {
            // @ts-ignore
            return e?.response

        }


    }
)



const adminSlice = createSlice({
    name: 'adminSlice',
    initialState: {
        admin: '',
        flipBooks: '',
        ebooks: '',
        templates: '',
        covers: '',
        users: '',
        plans: '',
        ebook: '',
        userPlans:'',
        adminDashboard:'',


    },
    reducers: {},
    extraReducers: (builder) => {

        builder.addCase(adminDetails.fulfilled, (state, action) => {
            if (action.payload?.status) state.admin = action.payload?.data
            else state.admin = ''
        })
        builder.addCase(getEbooks.fulfilled, (state, action) => {
            if (action.payload?.status) state.ebooks = action.payload?.data
            else state.ebooks = ''
        })
        builder.addCase(getFlipBooks.fulfilled, (state, action) => {
            if (action.payload?.status) state.flipBooks = action.payload?.data
            else state.flipBooks = ''
        })
        builder.addCase(getTemplates.fulfilled, (state, action) => {
            if (action.payload?.status) state.templates = action.payload?.data
            else state.templates = ''
        })
        builder.addCase(getUsers.fulfilled, (state, action) => {
            if (action.payload?.status) state.users = action.payload?.data
            else state.users = ''
        })
        builder.addCase(getCovers.fulfilled, (state, action) => {
            if (action.payload?.status) state.covers = action.payload?.data
            else state.covers = ''
        })

        builder.addCase(getPlans.fulfilled, (state, action) => {
            if (action.payload?.status) state.plans = action.payload?.data
            else state.plans = ''
        })
        builder.addCase(getUserPlans.fulfilled, (state, action) => {
            if (action.payload?.status) state.userPlans = action.payload?.data
            else state.userPlans = ''
        })
        builder.addCase(getAdminDashboard.fulfilled, (state, action) => {
            if (action.payload?.status) state.adminDashboard = action.payload?.data
            else state.adminDashboard = ''
        })
        builder.addCase(getTemplateDetails.fulfilled, (state, action) => {
            if (action?.payload?.status) {
                if (action?.payload.data.content !== null) {
                    state.ebook = JSON.parse(action?.payload.data.content)
                }
                else state.ebook = ''
            }

        })

    }
})

const {reducer} = adminSlice
export default reducer