const authHeader = () => {
  const authToken = localStorage.getItem("authToken");
  if (authToken !== null) {
    return {
      Authorization: `Token ${authToken || null}`,
      // "Content-Type": "multipart/form-data",
    };
  }
};
export default authHeader;
