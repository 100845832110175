import { createSlice } from "@reduxjs/toolkit";

const initialState: {
  pages: { page: number }[];
} = {
  pages: [{ page: 0 }],
};

const pageSlice = createSlice({
  name: "page",
  initialState,
  reducers: {
    setNumpage: (state, action) => {
      const maxNumPages =
        action.payload.length &&
        Math.max(...action.payload.map((page: { page: number }) => page.page));
      const numPages = Array.from(Array(maxNumPages), (_, i) => ({
        page: i + 1,
      }));
      state.pages = numPages;
    },

    addPage: (state) => {
      state.pages.push({
        page:
          Math.max(...state.pages.map((pages) => pages.page)) < 1
            ? 1
            : Math.max(...state.pages.map((pages) => pages.page)) + 1,
      });
    },
  },
  extraReducers: (builder) => {},
});

export const { setNumpage, addPage } = pageSlice.actions;
const { reducer } = pageSlice;

export default reducer;
